import React from 'react';
import Routes from './routes';
import { Provider } from 'react-redux';
import {
  BrowserRouter
} from "react-router-dom";
import configureStore from './redux/store/configureStore';

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;