
export const PORTALLOGO_REQUEST = 'PORTALLOGO_REQUEST';
export const PORTALLOGO_SUCCESS = 'PORTALLOGO_SUCCESS';
export const PORTALLOGO_FAIL = 'PORTALLOGO_FAIL';

export const SIGNER_DETAIL_REQUEST = 'SIGNER_DETAIL_REQUEST';
export const SIGNER_DETAIL_SUCCESS = 'SIGNER_DETAIL_SUCCESS';
export const SIGNER_DETAIL_FAIL = 'SIGNER_DETAIL_FAIL';

export const CREATE_SIGNER_REQUEST = 'CREATE_SIGNER_REQUEST';
export const CREATE_SIGNER_SUCCESS = 'CREATE_SIGNER_SUCCESS';
export const CREATE_SIGNER_FAIL = 'CREATE_SIGNER_FAIL';


export const IPADDRESS_DETAIL_REQUEST = 'IPADDRESS_DETAIL_REQUEST';
export const IPADDRESS_DETAIL_SUCCESS = 'IPADDRESS_DETAIL_SUCCESS';
export const IPADDRESS_DETAIL_FAIL = 'IPADDRESS_DETAIL_FAIL';


export const CALLBACK_REQUEST = 'CALLBACK_REQUEST';
export const CALLBACK_SUCCESS = 'CALLBACK_SUCCESS';
export const CALLBACK_FAIL = 'CALLBACK_FAIL';


export const DOWNLOAD_SIGNEDDOCUMENT_REQUEST = 'DOWNLOAD_SIGNEDDOCUMENT_REQUEST';
export const DOWNLOAD_SIGNEDDOCUMENT_SUCCESS = 'DOWNLOAD_SIGNEDDOCUMENT_SUCCESS';
export const DOWNLOAD_SIGNEDDOCUMENT_FAIL = 'DOWNLOAD_SIGNEDDOCUMENT_FAIL';



export const RESET = 'RESET';


export const ISERRORSERVER = 'ISERRORSERVER';

