
// Enter your Api path 
export const apiPath = window.globalConfig.SigningServiceAPIUrl;

// base URL
export const baseURL = window.globalConfig.SigningServiceBaseUrl;

// Custom headers
export const headers = {
    headers : {
    'Authorization' : 'API_KEY '+window.globalConfig.SigningServiceAPIKey
    }
}

// Api endpoints with proper data
export const endPoints = {
    getSignerDetails : {endPoint: "/signing/GetSignerDetails/"},
    createSigningOrder:{endPoint:"/signing/CreateSigningOrder"},
    downloadSignedDocument:{endPoint:"/signing/DownloadSignedDocument/"},
    callback:{endPoint:"/signing/Callback"},
    getIpAddress:{endPoint:"https://api.ipify.org/?format=json"},
    appLogoApi:{endPoint:"/signing/GetSettings/"}
};


//Norway date time format
export const dateTimeFormat = { dateFormat:"DD.MM.YYYY, HH.mm" , TimeFormat:"HH:mm", dateOnlyFormat: "DD.MM.YYYY"}