import React, { Component } from 'react';
import { withRouter } from "react-router";
import queryString from 'query-string';
import {callback } from '../../redux/actions/SignerAction';
import Loading from '../Loader/Loader';
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import ErrorPage from '../Error/ErrorPage';
class Callback extends Component {

    constructor(props) {
        super(props)
        this.state = {
            State: "",
            SessionState: "",
            Code: "",
            response: {},
            genericError: false,
            portallogo: "",
            languageCode: "",
            signerId: "",
            i18props: props.i18n,
            onlyOnce: false
        }
    }
    componentDidMount() {
        let authorizationResponse = {
            State: "",
            SessionState: "",
            Code: "",
            SignerId: ""
        }
        const urlParams = this.props.location.search;
        if (urlParams) {
            const queryparam = queryString.parse(urlParams);
            if ("state" in queryparam) {
                authorizationResponse.State = queryparam.state;
                authorizationResponse.SignerId = queryparam.state;
                this.setState({
                    signerId: queryparam.state
                });
            }
            if ("session_state" in queryparam) {
                authorizationResponse.SessionState = queryparam.session_state
            }
            if ("code" in queryparam) {
                authorizationResponse.Code = queryparam.code;

            }
        }
        this.props.callback(authorizationResponse);
    }

    static getDerivedStateFromProps(nextprops, prevState) {
        let updateState = { ...prevState };
        const settings = nextprops.Callback.data;
        if (settings !== null && settings !== "" && settings !== undefined) {
            updateState = {
                ...updateState,
                portallogo: settings.ImageUrl,
                languageCode: settings.LanguageCode
            };
        }
        return updateState;
    }
    componentDidUpdate(prevProps) {
        if (this.props.Callback !== prevProps.Callback) {
            this.handleLanguageChange();
        }
    }

    handleLanguageChange = () => {
        const languageCode = this.state.languageCode
        if (languageCode == 1033) {
            this.props.i18n.changeLanguage('en');
            localStorage.setItem('language', 'en');

        }
        else if (languageCode == 1044) {
            this.props.i18n.changeLanguage('no');
            localStorage.setItem('language', 'no');
        }
    }
    render() {
        return (
            <div>
                {!this.props.error && this.props.fetching && <Loading />}
                <div>
                    <div className="navbar navbar-inverse navbar-fixed-top">
                        <div className="container justify-content-center">
                            <div className="navbar-header">
                                <img src={this.state.portallogo} height="50" className="navbar-brand" alt="E-Sign Portal" />
                            </div>
                        </div>
                    </div>
                    {this.props.error || this.state.genericError ? <ErrorPage /> :
                        <div className="successmessage">
                            <h1 className="display-3">{this.props.t('esign.thankyouMsg')}</h1>
                            <FontAwesomeIcon className="successicon" icon={faCheck} />
                            <p>{this.props.t('esign.documentSignerMsg')}</p>
                        </div>
                    }
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    Callback: state.signer.callback,
    fetching: state.signer.fetching,
    error: state.error.isError,
    responseData: state.signer.responseCallback
})

const mapDispatchToProps = {
    callback
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Callback)));
