import React from 'react';
import SignerDetails from './components/SignerDetails/SignerDetails';
import NotFound from './components/NotFound/index';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import Callback from './components/Callback/Callback';

const Routes = () => (
	<Router >
		<Switch>
			<Route exact path="/">
				<SignerDetails />
			</Route>
			<Route path="/callback">
				<Callback />
			</Route>
			<Route path="/*">
				<NotFound />
			</Route>
		</Switch>
	</Router>
);

export default Routes;